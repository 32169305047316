import React from 'react';
import { FaRegTrashAlt, FaSchool } from 'react-icons/fa';
import { MdInfoOutline, MdOutlineAdminPanelSettings } from 'react-icons/md';

import { SchoolModal } from './components/SchoolModal';
import { SchoolDeleteModal } from './components/SchoolDeleteModal';
import { SchoolAdminModal } from './components/SchoolAdminModal';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  listSchoolsAction,
  openSchoolModalAction,
  selectListSchools,
  getSchoolAction,
  openSchoolDeleteModalAction,
  openSchoolAdminModalAction,
} from './schoolSlice';
import { selectCanWriteSchool } from 'features/user/userSlice';

export default function SchoolListPage() {
  const dispatch = useAppDispatch();

  const schools = useAppSelector(selectListSchools);
  const canWrite = useAppSelector(selectCanWriteSchool);

  React.useEffect(() => {
    dispatch(listSchoolsAction());
  }, []);

  const onCreate = () => {
    dispatch(openSchoolModalAction({ type: 'create' }));
  };

  const onRead = (schoolId: string) => {
    dispatch(getSchoolAction({ id: schoolId }));
    if (canWrite) {
      dispatch(openSchoolModalAction({ type: 'update', schoolId }));
    } else {
      dispatch(openSchoolModalAction({ type: 'view', schoolId }));
    }
  };

  const onDelete = (schoolId: string) => {
    dispatch(openSchoolDeleteModalAction({ schoolId }));
  };

  const onOpenAdmin = (schoolId: string) => {
    dispatch(openSchoolAdminModalAction({ schoolId }));
  };

  return (
    <div className="container kd-main">
      <div className="d-flex justify-content-between py-3">
        <h2>學校列表</h2>
        {canWrite &&
          <button className="btn btn-primary px-5 my-1" onClick={onCreate}><FaSchool className="mb-1 me-2" />新增學校</button>
        }
      </div>
      <table className="table table-hover">
        <thead className="table-secondary" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            <th scope="col" rowSpan={2}>#</th>
            <th scope="col" rowSpan={2}>名稱</th>
            <th
              scope="col"
              colSpan={3}
              style={{ textAlign: 'center', backgroundColor: 'lightblue' }}
            >
              能力現況 AAIS
            </th>
            <th
              scope="col"
              colSpan={3}
              style={{ textAlign: 'center', backgroundColor: 'lightgreen' }}
            >
              情緒行為 EBAS
            </th>
            <th scope="col" rowSpan={2}>狀態</th>
            <th scope="col" rowSpan={2}>操作</th>
          </tr>
          <tr>
            <th scope="col" className="fw-light border-start" style={{ textAlign: 'center', backgroundColor: 'lightblue' }}>AAIS<br/>幼兒版已用</th>
            <th scope="col" className="fw-light" style={{ textAlign: 'center', backgroundColor: 'lightblue' }}>AAIS<br/>學齡版已用</th>
            <th scope="col" className="fw-light" style={{ textAlign: 'center', backgroundColor: 'lightblue' }}>AAIS<br/>組合版已用</th>
            <th scope="col" className="fw-light border-start" style={{ textAlign: 'center', backgroundColor: 'lightgreen' }}>EBAS<br/>幼兒版已用</th>
            <th scope="col" className="fw-light" style={{ textAlign: 'center', backgroundColor: 'lightgreen' }}>EBAS<br/>學齡版已用</th>
            <th scope="col" className="fw-light border-end" style={{ textAlign: 'center', backgroundColor: 'lightgreen' }}>EBAS<br/>組合版已用</th>
          </tr>
        </thead>
        <tbody>
          {schools.map((school, index) => 
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{school.name}</td>
              <td className="border-start">{school.quota_usage.AAIS_3TO6_SCHOOL}</td>
              <td>{school.quota_usage.AAIS_OVER6_SCHOOL}</td>
              <td>{school.quota_usage.AAIS_SCHOOL}</td>
              <td className="border-start">{school.quota_usage.EBAS_3TO6_SCHOOL}</td>
              <td>{school.quota_usage.EBAS_OVER6_SCHOOL}</td>
              <td className="border-end">{school.quota_usage.EBAS_SCHOOL}</td>
              <td>{school.state}</td>
              <td className="d-flex justify-content-end">
                  {school.can_read &&
                    <button className="btn btn-outline-primary btn-sm me-2" onClick={() => onRead(school.id)}>
                     <MdInfoOutline className="mb-1" /> 資訊與點數
                    </button>
                  }
                  {canWrite && <button className="btn btn-outline-secondary btn-sm me-2" onClick={() => onOpenAdmin(school.id)}>
                    <MdOutlineAdminPanelSettings className="mb-1" /> 管理者
                  </button>}
                  {canWrite &&
                    <button className="btn btn-outline-danger btn-sm" onClick={() => onDelete(school.id)}>
                     <FaRegTrashAlt className="mb-1" /> 刪除
                    </button>
                  }
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <SchoolAdminModal />
      <SchoolModal />
      <SchoolDeleteModal />
    </div>
  );
}
