import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  closeStudentModalAction,
  listTeacherStudentsAction,
  selectListStudentModal,
  selectOpenedStudentModalTeacherInfo,
} from '../schoolSlice';

export function StudentModal() {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectListStudentModal);
  const teacher = useAppSelector(selectOpenedStudentModalTeacherInfo);

  React.useEffect(() => {
    if (modal.schoolId && modal.visible) {
      dispatch(listTeacherStudentsAction({
        school_id: modal.schoolId,
        teacher_id: modal.teacherId,
      }));
    }
  }, [modal.visible, modal.schoolId, modal.teacherId]);

  const onHide = () => {
    dispatch(closeStudentModalAction());
  };

  // Calculate AAIS and EBAS totals for each student and overall
  const studentData = modal.students.map(student => {
    const aaisTotal = (student.quota_usage.AAIS_3TO6_SCHOOL || 0) +
                      (student.quota_usage.AAIS_OVER6_SCHOOL || 0) +
                      (student.quota_usage.AAIS_SCHOOL || 0);
    const ebasTotal = (student.quota_usage.EBAS_3TO6_SCHOOL || 0) +
                      (student.quota_usage.EBAS_OVER6_SCHOOL || 0) +
                      (student.quota_usage.EBAS_SCHOOL || 0);
    return { name: student.name, aaisTotal, ebasTotal };
  });

  const totals = studentData.reduce(
    (acc, student) => ({
      aaisTotal: acc.aaisTotal + student.aaisTotal,
      ebasTotal: acc.ebasTotal + student.ebasTotal,
    }),
    { aaisTotal: 0, ebasTotal: 0 }
  );

  return (
    <Modal show={modal.visible} onHide={onHide} size="lg">
      <Modal.Header className="bg-info text-light" closeButton>
        <Modal.Title>老師: {teacher?.user_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped">
          <thead>
            {/* Column Headers */}
            <tr className="table-dark">
              <th scope="col">名稱</th>
              <th scope="col">AAIS能力現況 已使用點數</th>
              <th scope="col">EBAS情緒行為 已使用點數</th>
            </tr>
          </thead>
          <tbody>
            {studentData.map((student, index) => (
              <tr key={index}>
                <td>{student.name}</td>
                <td>{student.aaisTotal}</td>
                <td>{student.ebasTotal}</td>
              </tr>
            ))}
            {/* Summary Row */}
            <tr className="table-primary fw-bold">
              <td>總計</td>
              <td>{totals.aaisTotal}</td>
              <td>{totals.ebasTotal}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Button variant="secondary" onClick={onHide}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  );
}