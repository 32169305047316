import { FaRegTrashAlt, FaListOl } from 'react-icons/fa';

import { SchoolMember } from 'types';
import { useAppDispatch } from 'app/hooks';
import { openStudentModalAction, openTeacherDeleteModalAction } from '../schoolSlice';

type Props = {
  schoolId: string;
  item: SchoolMember;
  index: number;
};

const UserStateMap = {
  enabled: '啟用',
  disabled: '停用',
  removed: '已移除',
  inviting: '邀請中',
};
export function SchoolTeacherRow({ schoolId, item, index }: Props) {
  const dispatch = useAppDispatch();

  if ('role_binding_id' in item) {
    const onShowStudents = () => {
      dispatch(openStudentModalAction({
        schoolId: schoolId,
        teacherId: item.user_id,
      }));
    };

    const onDelete = () => {
      dispatch(openTeacherDeleteModalAction({
        schoolId,
        roleBindingId: item.role_binding_id,
      }));
    }
    return (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{item.user_name}</td>
        <td>{item.user_email}</td>
        <td>{UserStateMap[item.user_state]}</td>
        <td className="d-flex justify-content-end">
            <button className="btn btn-outline-primary btn-sm me-2" onClick={onShowStudents}>
              <FaListOl className="mx-1 mb-1" /> 學生及點數用量
            </button>
            <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>
              <FaRegTrashAlt className="mx-1 mb-1" /> 刪除此帳號
            </button>
        </td>
      </tr>
    );
  }

  const onDelete = () => {
    dispatch(openTeacherDeleteModalAction({
      schoolId,
      invitingUserId: item.inviting_user_id,
    }));
  }
  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td></td>
      <td>{item.user_email}</td>
      <td>{UserStateMap[item.user_state]}</td>
      <td className="d-flex justify-content-end">
        <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>
          <FaRegTrashAlt />
        </button>
      </td>
    </tr>
  );
}
