import React from 'react';
import { useParams } from 'react-router';
import { FaUserPlus } from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import { SchoolModal } from './components/SchoolModal';
import { SchoolTeacherRow } from './components/SchoolTeacherRow';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  createSchoolTeacherAction,
  listSchoolTeachersAction,
  selectSchoolInfo,
  selectSchoolTeachers,
  getSchoolAction,
} from './schoolSlice';
import { TeacherDeleteModal } from './components/TeacherDeleteModal';
import { StudentModal } from './components/StudentModal';

type FormData = {
  user_email: string;
};

const initialForm: FormData = {
  user_email: '',
};

export default function TeacherListPage() {
  const dispatch = useAppDispatch();
  const params = useParams<{ school_id: string; }>();

  const teachers = useAppSelector(selectSchoolTeachers);
  const school = useAppSelector((state) => selectSchoolInfo(state, params.school_id));
  const { handleSubmit, register, reset, watch, formState: { errors } } = useForm({
    defaultValues: initialForm,
  });
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    dispatch(listSchoolTeachersAction({ id: params.school_id }));
    dispatch(getSchoolAction({ id: params.school_id }));
  }, [params.school_id, dispatch]);

  React.useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
  }, [watch('user_email')]);

  const onAdd = async(body: FormData) => {
    let result = await dispatch(createSchoolTeacherAction({
      id: params.school_id,
      user_email: body.user_email,
    }));
    if (createSchoolTeacherAction.fulfilled.match(result)) {
      reset(initialForm);
      dispatch(listSchoolTeachersAction({ id: params.school_id }));
    } else {
      const error = result.error;
      let message = '新增老師時發生錯誤';
      if (error.message?.includes('E11000 duplicate key error')) {
        message = '此老師已加入該學校';
      } else if (error.message) {
        message = error.message;
      }
      setErrMsg(message ?? result.error.name ?? 'Unknown');
    }
  };

  // Calculate summed values for AAIS and EBAS
  const aaisUsed = (school?.quota_usage?.AAIS_3TO6_SCHOOL || 0) +
                   (school?.quota_usage?.AAIS_OVER6_SCHOOL || 0) +
                   (school?.quota_usage?.AAIS_SCHOOL || 0);
  const aaisQuota = (school?.quota?.AAIS_3TO6_SCHOOL || 0) +
                    (school?.quota?.AAIS_OVER6_SCHOOL || 0) +
                    (school?.quota?.AAIS_SCHOOL || 0);
  const ebasUsed = (school?.quota_usage?.EBAS_3TO6_SCHOOL || 0) +
                   (school?.quota_usage?.EBAS_OVER6_SCHOOL || 0) +
                   (school?.quota_usage?.EBAS_SCHOOL || 0);
  const ebasQuota = (school?.quota?.EBAS_3TO6_SCHOOL || 0) +
                    (school?.quota?.EBAS_OVER6_SCHOOL || 0) +
                    (school?.quota?.EBAS_SCHOOL || 0);

  return (
    <div className="container kd-main">
      <div className="d-flex justify-content-between my-3">
        <h2 className="w-100">{school?.name} 老師列表</h2>
        <form className="input-group flex-shrink-1" onSubmit={handleSubmit(onAdd)}>
          <label htmlFor="add-teacher-byemail" className="input-group-text fw-bold">加入名單</label>
          <input type="text" id="add-teacher-byemail" placeholder="輸入老師Email" className="form-control" {...register('user_email')}/>
          <button className="btn btn-primary" type="submit">
            <FaUserPlus className="text-light" /> 新增
          </button>
        </form>
        <span className="text-danger">{errMsg ?? errors.user_email?.message}</span>
      </div>

      {school && (
        <div className="my-1">
          <div className="rounded">
            <table className="table table-light table-striped">
              <thead>
                <tr>
                  <th scope="col">類型</th>
                  <th scope="col">已用數量</th>
                  <th scope="col">點數餘額</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>EBAS情緒行為</strong></td>
                  <td>{ebasUsed}</td>
                  <td>{ebasQuota}</td>
                </tr>
                <tr>
                  <td><strong>AAIS能力現況</strong></td>
                  <td>{aaisUsed}</td>
                  <td>{aaisQuota}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <table className="table table-hover">
        <thead className="table-secondary" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            <th scope="col">#</th>
            <th scope="col">名稱</th>
            <th scope="col">信箱</th>
            <th scope="col">狀態</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {teachers.map((teacher, index) => (
            <SchoolTeacherRow key={index} schoolId={params.school_id} item={teacher} index={index} />
          ))}
        </tbody>
      </table>
      <SchoolModal />
      <TeacherDeleteModal />
      <StudentModal />
    </div>
  );
}