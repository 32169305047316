import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { route_path } from 'app/route';
import {
  selectCanReadGiftCardBatch,
  selectCanReadParents,
  selectCanReadSchools,
  selectSchoolWithRoles,
} from 'features/user/userSlice';
import { FaSchool } from 'react-icons/fa';
import { BsPeopleFill, BsFillPersonFill } from 'react-icons/bs';
import { RiCoupon3Fill } from 'react-icons/ri';



export default function SideBar() {
  const canReadParent = useAppSelector(selectCanReadParents);
  const canReadSchools = useAppSelector(selectCanReadSchools);
  const canReadGiftCardBatch = useAppSelector(selectCanReadGiftCardBatch);
  const schools = useAppSelector(selectSchoolWithRoles);
  return (
    <div className="container bg-light kd-sidebar">

      <ul className="ms-2 pt-4 list-unstyled">
        <strong className="mb-2 pb-2 d-block border-bottom">功能選單</strong>
        {canReadGiftCardBatch && 
          <li className="m-1">
            <NavLink
              to={route_path.giftCardBatch}
              className={(isActive: boolean) => `sidebar-link ${isActive ? 'active' : ''}`}
            >
              <RiCoupon3Fill className="mb-1 me-2" /> 禮物卡管理
            </NavLink>
          </li>
        }
        {canReadParent && 
          <li className="m-1">
            <NavLink
              to={route_path.parents}
              className={(isActive: boolean) => `sidebar-link ${isActive ? 'active' : ''}`}
            >
              <BsFillPersonFill className="mb-1 me-2" /> 家長管理
            </NavLink>
          </li>
        }
        {canReadSchools && 
          <li className="m-1">
            <NavLink
              to={route_path.schools}
              className={(isActive: boolean) => `sidebar-link ${isActive ? 'active' : ''}`}
            >
              <FaSchool className="mb-1 me-2" /> 學校管理
            </NavLink>
          </li>
        }
        <strong className="mb-2 mt-5 pb-2 d-block border-bottom">老師帳號管理</strong>
        {schools.map((school) => 
          <div key={school.id}>
            <li className="m-1">
              <NavLink
                to={route_path.school_teachers.replace(':school_id', school.id)}
                className={(isActive: boolean) => `sidebar-link ${isActive ? 'active' : ''}`}
              >
                <BsPeopleFill className="mb-1 me-2" /> {school.name} 老師管理
              </NavLink>
            </li>
          </div>
        )}
      </ul>
    </div>
  );
}
